const MODAL_SEARCH_OPEN_CLOSE_STATE_CHANGE_EVENT_NAME = 'modalSearch:openCloseStateChange';

import {isMediumScreenAndUp} from '../../modules/breakpoint.js';

class ModalSearch {
    #instantSearch;
    #isInitialized = false;
    #isAnimating = false;
    #animation;
    #supportsAnimation;

    /** @type {HTMLElement} */
    #container;

    /** @type {HTMLElement} */
    #mainNavSearchbar;

    constructor() {
        this.#container = document.getElementById('modalSearch');
        this.#mainNavSearchbar = document.querySelector('.primaryNavigationBar__searchbar');
        this.#supportsAnimation = 'animate' in this.#container;

        document.querySelector('.searchToolbar__closeButton').addEventListener('click', () => {
            this.hideModal();
        });

        this.#container.addEventListener('click', this.#handleClick);
    }

    #initModalSearch = async () => {
        if (this.#isInitialized) {
            return;
        }

        this.#isInitialized = true;

        window.addEventListener('keydown', this.#handleKeyPress);

        try {
            const {searchBox} = await import('instantsearch.js/es/widgets/index.js');
            const {createSearchInstance} = await import('../../components/search/index.js');
            
            this.#instantSearch = createSearchInstance({
                searchBoxes: [
                    searchBox({
                        container: '#searchbox',
                    }),
    
                    searchBox({
                        container: this.#mainNavSearchbar,
                    }),
                ],
                hitsContainer: '#modalSearch-hits',
                suggestionsContainer: '#modalSearch-suggestions',
            });
        } catch (error) {
            window.dispatchEvent(new CustomEvent('log:error', {detail: `Search Module Failed: ${error.message}`}));
        }
    };

    #modalIsVisible = () => {
        return !!this.#container?.classList.contains('modalSearch--open');
    };

    #showSearchbar = (show = true) => {
        if (show !== false) {
            this.#mainNavSearchbar.classList.add('primaryNavigationBar__searchbar--active');
        } else {
            this.#mainNavSearchbar.classList.remove('primaryNavigationBar__searchbar--active');
        }
    };

    #enableSearchbarTabNavigation = (enable = true) => {
        this.#mainNavSearchbar.style.visibility = enable ? '' : 'hidden';
    };

    #disableSearchbarTabNavigation = () => {
        this.#enableSearchbarTabNavigation(false);
    };

    #hideSearchbar = () => {
        this.#showSearchbar(false);
    };

    showModal = (animated = true, show = true) => {
        if (this.#isAnimating) {
            return;
        }

        if (show && !this.#isInitialized) {
            this.#initModalSearch();
        }

        this.#triggerStateChangeEvent(show);

        if (animated === false || !this.#supportsAnimation) {
            return this.#onShowModalComplete(show);
        }

        if (show) {
            this.#animateShowModal();
        } else {
            this.#animateHideModal();
        }
    };

    #animateShowModal = (show = true) => {
        if (show === false) {
            return this.#animateHideModal();
        }
        this.#isAnimating = true;

        this.#enableSearchbarTabNavigation();

        this.#showSearchbar();

        this.#container.style.display = 'block';
        this.#container.style.top = '-100%';
        this.#container.style.opacity = '0';

        this.#animation = this.#container.animate(
            {
                display: ['block', 'block'],
                opacity: [0, 1],
                transform: [`translateY(0)`, `translateY(100%)`],
            },
            {
                duration: 300,
                easing: 'ease-in-out',
            }
        );

        this.#animation.onfinish = this.#onShowModalComplete;
    };

    #onShowModalComplete = (show = true) => {
        this.#showSearchbar(show);

        const targetInput = isMediumScreenAndUp()
            ? this.#mainNavSearchbar?.querySelector('input')
            : document.getElementById('searchbox')?.querySelector('input');

        if (show === false) {
            this.#container.classList.remove('modalSearch--open');
            document.body.style.overflow = '';
            targetInput?.blur();
            this.#instantSearch?.clearQuery();
            this.#disableSearchbarTabNavigation();
        } else {
            this.#container.classList.add('modalSearch--open');
            document.body.style.overflow = 'hidden';
            targetInput?.focus();
        }

        this.#container.style.display = '';
        this.#container.style.top = '';
        this.#container.style.opacity = '';

        this.#isAnimating = false;
    };

    hideModal = (animated = true) => {
        if (!this.#modalIsVisible()) {
            return;
        }
        this.showModal(animated, false);
    };

    #animateHideModal = () => {
        this.#isAnimating = true;
        this.#hideSearchbar();
        this.#container.style.display = 'block';
        this.#container.style.top = '0';
        this.#container.style.opacity = '1';

        this.#animation = this.#container.animate(
            {
                top: ['0', '0'],
                opacity: [1, 0],
                transform: [`translateY(0)`, `translateY(-100%)`],
            },
            {
                duration: 300,
                easing: 'ease-in-out',
            }
        );

        this.#animation.onfinish = this.#onHideModalComplete;
    };

    #onHideModalComplete = () => {
        this.#onShowModalComplete(false);
    };

    toggleModal = (animated = true) => {
        this.showModal(animated, !this.#modalIsVisible());
    };

    #handleKeyPress = (keyboardEvent) => {
        if (keyboardEvent.key === 'Escape' && this.#modalIsVisible() && !this.#isAnimating) {
            this.hideModal();
        }
    };

    #handleClick = ({/** @type {HTMLElement} */ target}) => {
        const targetUrl = target.closest('a')?.href;

        if (!targetUrl) {
            return;
        }

        const urlObject = new URL(targetUrl);

        if (
            urlObject.hash &&
            urlObject.origin + urlObject.pathname === window.location.origin + window.location.pathname
        ) {
            this.hideModal();
        }
    };

    /**
     * Dispatch event indicating change in menu open/close state
     * @param {boolean} open
     */
    #triggerStateChangeEvent(open) {
        document.dispatchEvent(
            new CustomEvent(MODAL_SEARCH_OPEN_CLOSE_STATE_CHANGE_EVENT_NAME, {
                detail: {
                    modalIsOpen: open,
                },
            })
        );
    }
}

if (document.getElementById('modalSearch')) {
    const modalSearch = new ModalSearch();
    const searchButtons = document.querySelectorAll('.js-modalSearchButton');

    if (searchButtons) {
        searchButtons.forEach((button) => {
            button.addEventListener('click', (clickEvent) => {
                clickEvent.preventDefault();
                modalSearch.toggleModal();
            });
        });
    }

    document.addEventListener('offCanvasMenu:stateChange', (event) => {
        if (event.detail.menuIsOpen) {
            modalSearch.hideModal();
        }
    });
}
